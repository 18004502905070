import React, { createContext, useContext, useReducer } from "react"
import ChallengeReducer from "./ChallengeReducer"
import * as type from "./ChallengeType"

const initialState = {
  autoPilotLists: [],
  hasBeenChecked: false,
  loadingChallenge: null,
  isRegistering: false,
  error: null,
  getUserContactLists: () => {},
  addToList: () => {},
  removeFromList: () => {}
}

export const ChallengeContext = createContext(initialState)
export const useChallenge = () => useContext(ChallengeContext)

const ChallengeState = ({ children }) => {
  const [state, dispatch] = useReducer(ChallengeReducer, initialState)

  // Get all lists the user is in ( Marketing Automation software )
  const getUserContactLists = async email => {
    dispatch({
      type: type.SET_LOADING,
      payload: true
    })

    const endpoint = `/.netlify/functions/getLists?email=${email}`
    await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(data => data.json())
      .then(response => {
        if (response.status === 200 && response.isOnList) {
          dispatch({
            type: type.GET_CHALLENGE_LISTS,
            list: response?.AutopilotLists,
            hasBeenChecked: response?.hasBeenChecked,
            loadingChallenge: false
          })
        } else if (
          response.name === "Error" ||
          response.error ||
          response.status !== 200
        ) {
          dispatch({
            type: type.GET_CHALLENGE_LISTS,
            list: [],
            hasBeenChecked: true,
            loadingChallenge: false
          })
        }
      })
  }

  // Add to AutoPilot List ( Marketing Automation software )
  const addToList = async (list_id, email) => {
    dispatch({
      type: type.IS_REGISTERING,
      error: null,
      payload: true
    })
    const endpoint = `/.netlify/functions/addToList?list_id=${list_id}&email=${email}`
    await fetch(endpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(data => data.json())
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: type.ADD_CHALLENGE_TO_LIST,
            isRegistering: false,
            payload: list_id
          })
        } else {
          dispatch({
            type: type.ERROR,
            isRegistering: false,
            error: "Something went wrong, please try to register again"
          })
        }
      })
  }

  const removeFromList = async (
    list_id,
    email,
    message = "Are you sure you want to cancel your registration?"
  ) => {
    const result = window.confirm(message)

    if (result) {
      dispatch({
        type: type.IS_REGISTERING,
        error: null,
        payload: true
      })

      const endpoint = `/.netlify/functions/removeFromList?list_id=${list_id}&email=${email}`
      await fetch(endpoint, {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(data => data.json())
        .then(response => {
          if (response.status === 200) {
            dispatch({
              type: type.REMOVE_CHALLENGE_FROM_LIST,
              payload: list_id,
              isRegistering: false
            })
          } else {
            dispatch({
              type: type.ERROR,
              isRegistering: false,
              error: "Something went wrong, please try to register again"
            })
          }
        })
    }
  }

  return (
    <ChallengeContext.Provider
      value={{
        autoPilotLists: state.autoPilotLists,
        hasBeenChecked: state.hasBeenChecked,
        loadingChallenge: state.loadingChallenge,
        isRegistering: state.isRegistering,
        getUserContactLists,
        addToList,
        removeFromList,
        error: state.error
      }}
    >
      {children}
    </ChallengeContext.Provider>
  )
}

export default ChallengeState
